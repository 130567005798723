<template>
    <div style="margin-top: 20px;">
      <img alt="KerryHub Image" src="../assets/KerryHub.png">

        <div style="font-size: 18px; margin-left: 10%; margin-right: 10%;">
            {{$t('subheader1')}}
        </div>
        <br />
        <div style="font-size: 16px; margin-left: 10%; margin-right: 10%;">
            {{$t('subheader2')}}
        </div>
        <br />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
    },
})
</script>
