<template>
  <section>
    <div>
      <div class="row justify-center">

        <div class="col-xs-8 col-sm-6 col-md-4 col-lg-2 text-bold">

          {{$t('validation_messages.generic_error_message1')}}
          <br />
          <br />
          {{$t('validation_messages.generic_error_message2')}}

        </div>

      </div>
    </div>
  </section>
</template>

