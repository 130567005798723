<template>
    <div>
        <div class="row justify-center">

          <div class="col-xs-8 col-sm-6 col-md-4 col-lg-2">

            {{$t('screen_text.notEligible')}}

          </div>

        </div>

    </div>
</template>